<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    page: {
        title: "Buat Jadwal Pra Asesmen",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Buat Jadwal Pra Asesmen",
            items: [
                {
                    text: "Validasi MUK",
                    href: "/validasi-muk",
                },
                {
                    text: "Buat Jadwal Pra Asesmen",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            tanggal_surat_tugas: new Date().toISOString().slice(0, 10),
            perihal_surat_tugas: "Surat Tugas Jadwal Pra Asesmen",
            nomor_surat_tugas: null,
            urutan_nomor: null,

            //modal
            showModalSimpan: false,

            pemilihan_asesor: [],
            optionsPendaftaran: [],
            optionsSkemaSertifikasi: [],
            optionsAsesor: [],
            optionsTUK: [],
            optionsDokumenPendaftaran: [],
            optionsDokumenMUKTerbit: [],

            pendaftaran_selected: null,
            loadingTable: false,
            pdf_surat_tugas: null,
            status: "draft",
            pra_asesmen_id: null,
            statusListDokumen: false,
            statusListDokumenMUKTerbit: false,

            modalPreviewDokumenMUK: false,
            formPreviewDocMUK: null,
            skema_id: null,
            backend_url: process.env.VUE_APP_BACKEND_URL + "/",
        };
    },
    mounted() {
        let self = this;
        self.getVerifikasiTUKSelesai();
        self.getSkemaSertifikasi();
        self.getTUK();
        self.getNomorSuratTugas();
        self.getPegawai();
    },
    methods: {
        addPemilihanAsesor() {
            this.pemilihan_asesor.push({
                skema_sertifikasi: null,
                asesi: null,
                tanggal_pelaksanaan: null,
                tuk: null,
            });
        },
        removePemilihanAsesor(index) {
            this.pemilihan_asesor.splice(index, 1);
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
        StoreData() {
            let self = this;
            Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Tunggu Sebentar",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    Swal.close();
                    self.showModalSimpan = false;
                    self.$router.push({ name: "konfirmasi-kehadiran_pra_asesmen" });
                }
            });
        },
        getVerifikasiTUKSelesai() {
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-pendaftaran-pra-asesmen",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsPendaftaran = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getSkemaSertifikasi() {
            // GET SKEMA SERTIFIKASI KATEGORI
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-skema-sertifikasi",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsSkemaSertifikasi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getTUK() {
            // GET SKEMA SERTIFIKASI KATEGORI
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-tuk",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsTUK = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getNomorSuratTugas() {
            let self = this;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/get-nomor-surat-tugas", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var next_nomor = response?.data?.data?.next_urutan;
                    var currentDate = new Date();
                    self.nomor_surat_tugas = next_nomor + "/JPA/LSP/" + self.convertToRoman(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
                    self.urutan_nomor = next_nomor;
                })
                .catch((e) => {
                    console.log(e);
                    // this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
        getPegawai() {
            // GET MASTER KARYAWAN
            let self = this;

            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            // load data master karyawan
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-pegawai",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsAsesor = response.data.data.referensi;
                        Swal.close();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        cariJadwalSertifikasi() {
            let self = this;
            self.loadingTable = true;
            var id = self.pendaftaran_selected?.id;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/get-data-pendaftaran/" + id,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var verifikasi_tuk = response_data_fix.data;
                        console.log(response_data_fix.skema_sertifikasi_id);
                        let clear_data = [];
                        $.each(verifikasi_tuk, function (indexInArray, valueOfElement) {
                            clear_data.push({
                                id: valueOfElement.id,
                                skema_sertifikasi: valueOfElement.skema_sertifikasi,
                                asesi: valueOfElement.pegawai,
                                tanggal_pelaksanaan: valueOfElement.tanggal_pelaksanaan,
                                tuk: null,
                                asesor_kompetensi: null,
                                asesor_peninjau: null,
                                no_registrasi: valueOfElement.no_registrasi,
                            });
                        });
                        self.skema_id = response_data_fix.skema_sertifikasi_id;
                        self.optionsDokumenPendaftaran = response_data_fix.pendaftaran_asesi;
                        self.pemilihan_asesor = clear_data;
                        console.log(self.pemilihan_asesor);
                        self.loadingTable = false;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1,
            };
            var str = "";

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        storeSuratTugas(status) {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var send_status = "2e5284fc-4e10-11ee-afa5-0242ac110006";
            if (status == "ajukan") {
                send_status = "6762a1dc-4e10-11ee-afa5-0242ac110006";
            }
            let required_form = new Promise(function (resolve, reject) {
                // cek required
                if (self.nomor_surat_tugas == "" || self.nomor_surat_tugas == null || self.tanggal_surat_tugas == "" || self.tanggal_surat_tugas == null || self.pendaftaran_selected == "" || self.pendaftaran_selected == null) {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan Formulir Terisi Lengkap",
                    });
                    reject("stop");
                }
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }
                axios
                    .post(
                        process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/store-surat-tugas",
                        {
                            nomor_surat_tugas: self.nomor_surat_tugas,
                            tanggal_surat_tugas: self.tanggal_surat_tugas,
                            perihal_surat_tugas: self.perihal_surat_tugas,
                            jadwal_sertifikasi_id: self.pendaftaran_selected?.id,
                            status_id: send_status,
                            urutan_nomor: self.urutan_nomor,
                            pemilihan_asesor: JSON.stringify(self.pemilihan_asesor),
                            skema_id: self.skema_id,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        console.log(response);
                        var res = response.data.data;

                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Surat Tugas Berhasil Disimpan",
                            text: "Surat Tugas Akan Segera Ditampilkan",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                Swal.close();
                                self.pdf_surat_tugas = res.pdf;
                                self.pra_asesmen_id = res.last_id;
                                self.statusListDokumen = true;
                                self.statusListDokumenMUKTerbit = true;
                            }
                        });
                    })
                    .catch((e) => {
                        self.axiosCatchError = e.response;
                        console.log(e);
                    });
            });
        },
        submitPraAsesmen(status) {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var send_status = "2e5284fc-4e10-11ee-afa5-0242ac110006";
            if (status == "ajukan") {
                send_status = "6762a1dc-4e10-11ee-afa5-0242ac110006";
            }
            let required_form = new Promise(function (resolve, reject) {
                // cek required
                if (self.nomor_surat_tugas == "" || self.nomor_surat_tugas == null || self.tanggal_surat_tugas == "" || self.tanggal_surat_tugas == null || self.pendaftaran_selected == "" || self.pendaftaran_selected == null) {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan Formulir Terisi Lengkap",
                    });
                    reject("stop");
                }
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }
                axios
                    .post(
                        process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/store-pra-asesmen",
                        {
                            status_id: send_status,
                            pra_asesmen_id: self.pra_asesmen_id,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        console.log(response);

                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman list pra asesmen segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                Swal.close();
                                self.$router.push({ name: "all-pra_asesmen" });
                            }
                        });
                    })
                    .catch((e) => {
                        self.axiosCatchError = e.response;
                        console.log(e);
                    });
            });
        },

        getFormDokumenMUK(index, dokumen_id) {
            let self = this;
            self.formPreviewDocMUK = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/preview-dokumen-muk", {
                    params: {
                        pra_asesmen_id: self.pra_asesmen_id,
                        dokumen_id: dokumen_id,
                        skema_id: self.skema_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data;
                    self.formPreviewDocMUK = res_fix.data.pdf;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });

            self.modalPreviewDokumenMUK = true;
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>Formulir Surat Tugas Jadwal Pra Asesmen</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Tanggal Surat Tugas <span class="required-field"></span></label>
                                                <input type="date" v-model="tanggal_surat_tugas" class="form-control" />
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nomor Surat Tugas <span class="required-field"></span></label>
                                                <input type="text" v-model="nomor_surat_tugas" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Perihal <span class="required-field"></span></label>
                                                <input type="text" v-model="perihal_surat_tugas" class="form-control" readonly />
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nomor Pendaftaran <span class="required-field"></span></label>
                                                <v-select :options="optionsPendaftaran" label="kode_jadwal" v-model="pendaftaran_selected" placeholder="Pilih Nomor Pendaftaran" @update:modelValue="cariJadwalSertifikasi()"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <!-- <label for="tipe_menu">Dokumen MUK</label> -->
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th style="width: 20%">Skema Sertifikasi</th>
                                                    <th style="width: 20%">Asesi</th>
                                                    <th style="width: 20%">Nomor Registrasi</th>
                                                    <th style="width: 15%">Tanggal Usulan Pelaksanaan</th>
                                                    <!-- <th style="width:5%;">
                                                        <button type="button" class="btn btn-sm btn-success" @click="addPemilihanAsesor()">
                                                            <i class="fa fa-plus"></i>
                                                        </button>
                                                    </th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="loadingTable">
                                                    <td colspan="3"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                </tr>
                                                <tr v-else-if="pemilihan_asesor.length == 0">
                                                    <td colspan="3">Pilih No Pendaftaran</td>
                                                </tr>
                                                <tr v-else v-for="(item, index) in pemilihan_asesor" :key="index">
                                                    <td>
                                                        {{ item.skema_sertifikasi.nama_skema_sertifikasi }}
                                                    </td>
                                                    <td>{{ item.asesi.npk }} - {{ item.asesi.nama }}</td>
                                                    <td>
                                                        {{ item.no_registrasi }}
                                                    </td>
                                                    <td>
                                                        <input readonly type="date" class="form-control" v-model="item.tanggal_pelaksanaan" />
                                                    </td>
                                                    <!-- <td>
                                                        <button type="button" class="btn btn-sm btn-danger" @click="removePemilihanAsesor(index)">
                                                            <i class="fas fa-times"></i>
                                                        </button>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-end">
                                            <button class="btn btn-primary" v-on:click="storeSuratTugas('draft')"><i class="fas fa-save"></i> Simpan Surat Tugas Pra Asesmen</button>
                                        </div>
                                    </div>
                                    <div class="alert alert-info mt-3" v-if="pdf_surat_tugas == null"><i class="fas fa-info"></i> Simpan Surat Tugas Pra Asesmen Terlebih Dahulu</div>
                                    <div class="row" v-if="pdf_surat_tugas">
                                        <h3>Preview Surat Tugas</h3>
                                        <div class="row p-4">
                                            <div class="card" style="outline-style: outset">
                                                <div class="card-body">
                                                    <iframe v-bind:src="pdf_surat_tugas" width="100%" height="400"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: none" class="form-group" v-if="statusListDokumen">
                                        <label for="tipe_menu">Daftar Asesi</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama Asesi</th>
                                                    <th>Preview</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="loadingTable">
                                                    <td colspan="3"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                </tr>
                                                <tr v-else-if="optionsDokumenPendaftaran.length == 0">
                                                    <td colspan="4">Dokumen Tidak Tersedia</td>
                                                </tr>
                                                <tr v-else v-for="(item, index) in optionsDokumenPendaftaran" :key="index">
                                                    <td>
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td>
                                                        {{ item.asesi_nama }}
                                                    </td>
                                                    <td>
                                                        <a target="blank" :href="backend_url + item.path_dokumen_apl_1" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> FR.APL 01</a>
                                                        <a target="blank" :href="backend_url + item.path_dokumen_apl_2" class="btn btn-info btn-sm m-1 rounded-0"><i class="fa fa-eye"></i> FR.APL 02</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer" v-if="pdf_surat_tugas">
                            <div class="row">
                                <div class="col-md-12 col-md-6">
                                    <div class="text-end">
                                        <button v-on:click="submitPraAsesmen('draft')" class="btn btn-warning" type="button"><i class="fas fa-edit"></i> Simpan Draft</button>
                                        <button class="btn btn-primary bg-primary m-1" v-on:click="submitPraAsesmen('ajukan')"><i class="fas fa-save"></i> Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
    <b-modal v-model="showModalSimpan" title="Konfigurasi Atasan" title-class="text-black font-18" body-class="p-3" size="md" hide-footer>
        <div class="row">
            <form @submit.prevent="StoreData">
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Manajer Sertifikasi</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Manajer Sertifikasi</option>
                        <option value="">Manajer Sertifikasi 1</option>
                        <option value="">Manajer Sertifikasi 2</option>
                    </select>
                </div>
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Manajer Pelatihan</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Manajer Pelatihan</option>
                        <option value="">Manajer Pelatihan 1</option>
                        <option value="">Manajer Pelatihan 2</option>
                    </select>
                </div>
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Ketua TUK</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Ketua TUK</option>
                        <option value="">Ketua TUK 1</option>
                        <option value="">Ketua TUK 2</option>
                    </select>
                </div>

                <div class="col-md-12 text-end mt-3">
                    <button class="btn btn-primary" type="submit"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="modalPreviewDokumenMUK" title="Preview Dokumen MUK" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
        <div class="row">
            <!-- <form @submit.prevent="StorePreviewMUK"> -->
            <!-- <div v-html="formPreviewDocMUK"></div> -->

            <iframe v-bind:src="formPreviewDocMUK" width="100%" height="400"></iframe>
            <!-- <div class="card-footer text-end">
                    <button class="btn btn-primary"><i class="fas fa-save"></i> Simpan</button>
                </div> -->
            <!-- </form> -->
        </div>
    </b-modal>
</template>
<style scoped>
.required-field::before {
    content: "*";
    color: red;
}
.disabled {
    pointer-events: none;
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    background-color: #eef1f6;
    border-color: #d1dbe5;
}
</style>
